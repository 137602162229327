 import Heading from "../Heading/Heading";
 import EmailInput from "../EmailBlock/EmailBlock";
 import { StyledWrapped, StyledSectionWrapper, StyledContainer, SpacerSmall, StyledMouse, StyledText, TitleWrapper, Spacer } from "./styled";

function SectionOne() {

  return (
    <StyledSectionWrapper>
        <Heading />
        <SpacerSmall />
    <StyledWrapped>
    <StyledContainer>
      <TitleWrapper>
        Generate  YouTube <br /> Thumbnails
      </TitleWrapper>
      <StyledText>Our Ai creates custom, attention-grabbing thumbnails in just seconds. Maximize clicks and views, helping you to reach a wider audience and grow on YouTube.</StyledText>
        <Spacer />
        <EmailInput />
        <SpacerSmall />
      <StyledMouse />
    </StyledContainer>
    </StyledWrapped>
    </StyledSectionWrapper>
  );
}

export default SectionOne;