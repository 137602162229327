import styled from "@emotion/styled";

export const StyledHeading = styled.header`
  display: flex;
  font-family: 'Mona Sans', sans-serif;
  justify-content: space-between;
  color:#FFFFFF;
  align-items: center;
  padding-top: 60px;
  padding-left: 100px;
  h1 {
    margin: 0;
    font-size: 40px;
  }
  
  @media (min-width: 340px) and (max-width: 800px) {
    padding-left: 30px;
    padding-top: 30px;
    font-size: 20px;
    h1 {
      margin: 0;
      font-size: 20px;
    }
  }

`;