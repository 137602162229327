import { useState } from 'react';
import * as React from 'react';
import axios from 'axios';
import {EmailInputContainer, Styledmain, Button, SuccessMessage, Label, Input, EmailIcon} from './styled';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Snackbar } from '@mui/material';

function EmailInput() {
    const [email, setEmail] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const baseURL = "https://khxgzdf6yb.execute-api.us-east-1.amazonaws.com/dev/user"
    const apiKey = "graphixai-niedfd-sbdse-fadcad"
  
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setIsSubmitted(false);
    };

    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
        props,
        ref,
      ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });

    const handleSubmit = async (e: MouseEvent) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${baseURL}`, { 'email': email  }, {
                headers: 
                {
                    'X-Api-Key': `${apiKey}`,
                }
            });
            setIsSubmitted(true);
            console.log(response.data);
        } catch (error) {
            console.error('Error submitting email:', error);
        }
    }

    return (
        <Styledmain>
        <EmailInputContainer>
            <Label>
                <EmailIcon/>
                <Input type="email" value={email} placeholder="Enter e-mail for early access" onChange={e => setEmail(e.target.value)} />
            </Label>
            <Button onClick={handleSubmit} >Submit</Button>
        </EmailInputContainer>
        <Snackbar open={isSubmitted} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
        You Email Has been Submitted Successfully
        </Alert>
      </Snackbar>      
      </Styledmain>
    );
}

export default EmailInput;