import styled from "@emotion/styled";

export const EmailInputContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 503px;
    height: 55px;
    padding: 8px;
    background: #FFFFFF;
    box-shadow: 0px 0px 48px rgba(0, 0, 0, 0.09);
    border-radius: 34.5px;

    @media (min-width: 340px) and (max-width: 800px) {
        width: 80%;
        height: 50px;
      }
`;

export const Styledmain = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
`
export const SuccessMessage = styled.text`
  font-size: 20px;
  color: black;
  margin-top: 8px;
  background: #FDDEEE;
  padding: 5px;
  border-radius: 30px;
`

export const Button = styled("button")<any>`
  font-family: Mona Sans, sans-serif;
  font-size: 1rem;
  color: #FFFFFF;
  font-weight: 700;
  border: 1px solid #edf3f5;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 16px 30px rgb(23 31 114 / 20%);
  width: 19%;
  height: 53px;
  background: #44229A;
  border-radius: 26.5px;
  justify-content: center;
  @media (min-width: 340px) and (max-width: 800px) {
    width: 25%;
    height: 97%;
    font-size: 0.7rem;
  }
  &:hover,
  &:active,
  &:focus {
    color: #fff;
    border: 1px solid rgb(255, 130, 92);
    background-color: rgb(255, 130, 92);
    @media (min-width: 340px) and (max-width: 800px) {
      width: 25%;
      height: 95%;
      font-size: 0.7rem;
    }
`;

export const Label = styled.label`
    font-family: 'Mona-Sans';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #2A2A2A;
    opacity: 0.7;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
input:focus {
    outline:none;
}
@media (min-width: 340px) and (max-width: 800px) {
  font-size: 15px;
  font-weight: 450;
}
`;

export const Input = styled.input`
padding-left: 2px;
width: 200px;
outline: none;
border: 0px solid;
input:focus {
    outline:none;
}
`;

export const EmailIcon = styled.div`
  background-image: url('./images/emailicon.svg');
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 4px;
  display: block;
  align-items: center;
  justify-content: center;
  width: 17px;
  height: 17px;
`;