import React from 'react';
import { StyledHeading } from './styled';

function Heading() {
  return (
    <StyledHeading>
      <h1>GraphixAi</h1>
    </StyledHeading>
  );
};

export default Heading;