import styled from "@emotion/styled";

export const StyledSectionWrapper = styled.section`
background-image: url('./images/background.svg'), url('./images/background2.svg');
  background-repeat: no-repeat;
  background-size: cover, cover;
  background-position: 87%, 40px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-color: white;
  @media (min-width: 340px) and (max-width: 420px) {
    background-image: url('./images/landingpagebackground.svg');
    background-size: 100vw;
    background-position: top;
    height: 495px;
  }
  @media (min-width: 420px) and (max-width: 500px) {
    background-image: url('./images/landingpagebackground.svg');
    background-size: 100vw;
    background-position: top;
    height: 600px;
  }

  @media (min-width: 500px) and (max-width: 600px) {
    background-image: url('./images/landingpagebackground.svg');
    background-size: 100vw;
    background-position: top;
    height: 100vh;
  }

  @media (min-width: 1000px) and (max-width: 2000px) {
    height: 100vh;
  }

  @media (min-width: 2000px) and (max-width: 3000px) {
    height: 100vh;
  }
`;

export const StyledHeading = styled.div`
font-family: 'Nunito Sans', sans-serif;
font-weight: 700;
font-size: 30px;
text-align: center;
align-items: center;
`;

export const StyledPurple = styled.text`
color: #44229A;
`;

export const TitleWrapper = styled.h1`
  padding-left: 100px;
  padding-right: 100px;
  text-align: center;
  color: white;
  padding-top: 200px;
  font-size: 45px;
  @media (min-width: 340px) and (max-width: 800px) {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0px;
    font-size: 32px;
    padding-bottom: 0px;
  }

  @media (min-width: 800px) and (max-width: 1000px) {
    padding-top: 10px;
  }
      
  @media (min-width: 1000px) and (max-width: 2000px) {
    padding-top: 40px;
  }

  @media (min-width: 2000px) and (max-width: 2500px) {
    padding-top: 120px;
  }
`;

export const Spacer = styled.div`
  height: 60px;
  @media (min-width: 375px) and (max-width: 800px) {
    height: 30px;
  }
`;

export const SpacerSmall = styled.div`
  height: 40px;

  @media (min-width: 340px) and (max-width: 800px) {
    height: 30px;
  }
`;


export const StyledText = styled.h3`
  font-family: 'Open Sans', sans-serif; 
  color: white;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  margin: 0px;
  padding-left: 500px;
  padding-right: 500px;
  @media (min-width: 340px) and (max-width: 2100px) {
    font-size: 14px;
    padding-left: 35px;
    padding-right: 35px;
  }

`
export const StyledContainer = styled.div`
  font-family: 'Mona Sans', sans-serif;
  display: flex;
  font-weight: 700;
  font-size: 60px;
  flex-direction: column;
  align-items: center;
  height: 100%;

  @media (min-width: 200px) and (max-width: 340px){
    font-size: 20px;
    text-align: center;
  }

  @media (min-width: 340px) and (max-width: 600px){
    font-size: 20px;
    text-align: center;
    height: 100%;
  }

  @media (min-width: 1000px) and (max-width: 1200px){
    font-size: 20px;
    text-align: center;
    height: 100%;
  }
`;

export const StyledWrapped = styled.div`
  padding-left: 300px;
  padding-right: 300px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 200px) and (max-width: 340px){
    height: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  @media (min-width: 340px) and (max-width: 600px){
    height: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  @media (min-width: 600px) and (max-width: 800px){
    height: 100%;
    padding-left: 70px;
    padding-right: 70px;
  }

  @media (min-width: 800px) and (max-width: 900px){
    height: 100%;
    padding-left: 70px;
    padding-right: 70px;
  }

  @media (min-width: 800px) and (max-width: 950px){
    height: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }

  @media (min-width: 950px) and (max-width: 1050px){
    height: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }
`;

export const StyledMouse = styled.div`
  background-image: url('./images/mouse.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 30px;
  height: 40px;

  @media (min-width: 340px) and (max-width: 1000px) {
    visibility: hidden;
  }
`;
